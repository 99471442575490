@use '@vlabs/uikit/src/styles/typography.sass' as *

.Title_sm
  padding: 10px 10px 0 10px

.Title__Wrapper
  display: flex
  justify-content: space-between
  align-items: center

.Title
  @include typ-subtitle-1

  &_success
    color: var(--color-positive)

  &_error
    color: var(--color-negative)

  &_unknown
    color: var(--color-attention)

.Title__Icon
  margin-right: 5px
  margin-top: 5px
  height: 1em

.Subtitle
  padding-top: 12px
  @include typ-subtitle-2

  .Title_sm &
    padding-top: 5px
    @include typ-body-2

.InfoText
  display: flex
  justify-content: center
  color: var(--color-on-surface)
  padding-top: 12px
  @include typ-small-1

  .Title_sm &
    padding-top: 5px

.InfoIcon
  margin-right: 5px
