.Image
  max-height: 360px
  max-width: 100%
  object-fit: contain
  border-radius: 4px

.InfoPanel
  display: flex
  border-radius: 8px
  padding: 12px
  background-color: var(--color-surface-container-high)
  width: 100%

.Icon
  width: 35px
  margin-right: 5px
  color: var(--color-on-surface)
