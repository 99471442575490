.StepperWrapper
  display: flex
  align-items: center
  flex-direction: column

.Step_sparse > *
  margin-top: 8px

.StepperButtons
  width: 700px
  border: 1px solid var(--color-outline-variant)
  border-radius: 0 0 8px 8px
  padding: 16px
