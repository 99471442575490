.Panel
  border: 1px solid var(--color-outline-variant)
  border-radius: 8px
  height: 100%

  &_noBorder
    border: 0

  &_withPadding
    padding: var(--space-sm)
