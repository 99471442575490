$button-size: 36px

@mixin _Button
  font-size: 14px
  letter-spacing: 0.1px
  height: $button-size
  position: relative
  display: inline-flex
  justify-content: center
  align-items: center
  padding: 0.5em 1em
  border: 1px solid var(--color-primary)
  border-radius: 4px
  cursor: pointer
  color: white
  background-color: var(--color-primary)
  transition: color, background-color 0.1s
  background-clip: padding-box

.Button
  @include _Button
  --overlay-border-radius: 4px

  &[disabled]
    cursor: default
    color: var(--color-on-surface-variant2)

    .InteractionOverlay
      display: none

  // --- kinds ---

  &_primary
    --color: var(--color-primary)
    --on-color: var(--color-on-primary)

  &_negative
    --color: var(--color-negative)
    --on-color: var(--color-on-negative)

  &_attention
    --color: var(--color-attention)
    --on-color: var(--color-on-attention)

  &_positive
    --color: var(--color-positive)
    --on-color: var(--color-on-positive)

  &_neutral
    --color: var(--color-neutral)
    --on-color: var(--color-on-neutral)

  // --- variants ---

  &_filled
    background-color: var(--color)
    border-color: var(--color)
    color: var(--on-color)
    --overlay-bg: var(--on-color)

    &[disabled]
      background-color: var(--color-disabled-background)
      border-color: transparent

  &_outlined
    background: transparent
    color: var(--color)
    border-color: var(--color)

    &[disabled]
      border-color: var(--color-outline-variant)

  &_flat,
  &_invisible,
  &_dimmed
    color: var(--color)
    border-color: transparent
    background: transparent

    &[disabled]
      background: transparent
      border-color: transparent

  &_flat,
  &_outlined
    --overlay-bg: var(--color)

  &_invisible
    --hover-bg-color: transparent
    --active-bg-color: transparent

  &_dimmed
    color: var(--color-neutral)

    &:hover:not(&[disabled])
      color: var(--color)

  &_dimmed,
  &_invisible
    .Button__Overlay
      display: none

  // --- modifiers ---

  &_fullWidth
    width: 100%
    font-weight: 500

.RoundButton
  border-radius: 50%
  padding: 6px
  font-size: 16px
  width: $button-size

  .Button__Content
    justify-content: center

  .Button__Overlay
    border-radius: 50%

.Button__Icon
  height: 1.2em

  svg
    height: 100%
    width: auto
    display: block

.Button__Content
  overflow: hidden
  position: relative
  white-space: nowrap
  text-overflow: ellipsis
  display: flex
  align-items: center
