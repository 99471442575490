
.State
  display: inline-block
  width: 10px
  height: 10px
  border-radius: 50%
  background: transparent
  border: 1px solid var(--color-neutral)
  margin-right: 10px

  &_loaded
    background-color: var(--color-positive-fixed)
    border-color: var(--color-positive-fixed)

  &_failed
    background-color: var(--color-negative-fixed)
    border-color: var(--color-negative-fixed)

.DocsLink
  color: var(--color-neutral)
  transition: 0.3s color

  &:hover,
  &:focus
    color: var(--color-primary)

.GoodResponse
  display: flex
