*[data-theme="dark"]:not(#fake-id) {
  color-scheme: dark;

  --color-primary: var(--palette-primary80);
  --color-on-primary: var(--palette-primary6);
  --color-primary-container: var(--palette-primary10);
  --color-on-primary-container: var(--palette-primary90);

  --color-neutral: var(--palette-neutral80);
  --color-on-neutral: var(--palette-neutral6);
  --color-neutral-container: var(--palette-neutral30);
  --color-on-neutral-container: var(--palette-neutral90);

  --color-positive: var(--palette-positive80);
  --color-on-positive: var(--palette-positive6);
  --color-positive-container: var(--palette-positive30);
  --color-on-positive-container: var(--palette-positive90);
  --color-positive-container-dim: var(--palette-positive12);

  --color-attention: var(--palette-attention87);
  --color-on-attention: var(--palette-attention6);
  --color-attention-container: #806010;
  --color-on-attention-container: var(--palette-attention90);
  --color-attention-container-dim: #604505;

  --color-negative: var(--palette-negative80);
  --color-negative-container: var(--palette-negative30);
  --color-on-negative: var(--palette-negative6);
  --color-on-negative-container: var(--palette-negative90);
  --color-negative-container-dim: var(--palette-negative10);

  --color-background: var(--palette-neutral10);
  --color-on-background: var(--palette-neutral90);

  --color-outline: var(--palette-neutral-variant60);
  --color-outline-variant: var(--palette-neutral-variant30);
  --color-outline-variant2: var(--palette-neutral-variant12);

  --color-disabled-background: var(--palette-neutral20);
  --color-disabled-foreground: var(--palette-neutral40);

  --color-inverse-on-surface: var(--palette-neutral10);
  --color-inverse-surface: var(--palette-neutral90);
  --color-inverse-primary: var(--palette-primary40);

  --color-nonactive-standout: var(--palette-neutral22);

  --color-surface: var(--palette-neutral6);
  --color-on-surface: var(--palette-neutral80);
  --color-surface-tint: var(--palette-primary80);
  --color-surface-variant: var(--palette-neutral-variant30);
  --color-on-surface-variant: var(--palette-neutral-variant60);
  --color-on-surface-variant2: var(--palette-neutral-variant40);
  --color-surface-container-highest: var(--palette-neutral22);
  --color-surface-container-high: var(--palette-neutral17);
  --color-surface-container: var(--palette-neutral12);
  --color-surface-container-low: var(--palette-neutral10);
  --color-surface-container-lowest: var(--palette-neutral4);
  --color-surface-dim: var(--palette-neutral6);
  --color-surface-bright: var(--palette-neutral24);
}

/* where нужен чтобы не повышать специфичность */
:where(*[data-theme="dark"]) a {
  color: var(--color-primary)
}