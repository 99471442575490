.GenericPolicySubForm
  .SettingsItemWrapper
    padding: 0
  .SettingsItemWrapper__Title
    padding-right: var(--space-sm)
    width: auto
    font-weight: 400
  .Grid
    padding-top: var(--space)
  .GridRow
    margin-top: var(--space-sm)
  .GridCol
    padding-top: 0
    padding-bottom: 0

.GenericPolicySubForm
  .Fold__Body &
    padding: 0 var(--space-sm) var(--space-sm)

.Border
  border-top: 1px solid var(--color-outline-variant)

.SettingsSubForm__AgeInputs
  width: 70px

.SettingsSubForm__AgeFilter
  display: flex
  align-items: flex-end

.SettingsSubForm__AgeDivider
  margin: 12px 6px
