@use '@vlabs/uikit/src/styles/typography.sass' as *

.Root
  margin: 2px
  display: flex
  justify-content: space-between

  &_column
    display: block

.Title
  @include typ-body-2
  display: flex
  word-break: normal
  margin-right: 10px
  align-items: center
  min-width: max-content

.TitleIcon
  height: 20px

.Value
  @include typ-subtitle-2
  display: flex
  align-items: center

  &_column
    display: block

.Icon
  margin-left: 5px
  margin-right: -5px !important
