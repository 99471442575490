.Link
  text-decoration: none
  display: inline-flex
  transition: color 0.2s
  position: relative
  align-items: center
  color: var(--color-on-surface-variant2)
  cursor: default

  &_withIcon
    padding-bottom: 2px

  &:not(&_disabled)
    color: var(--color-primary)
    cursor: pointer

    &::after
      content: ''
      display: block
      position: absolute
      bottom: 0
      left: 0
      width: 0
      height: 1px
      background: var(--color-primary)
      transition: width 0.2s

    &:hover::after
      width: 100%

  .Link__Icon
    height: 20px !important

    svg
      height: 20px !important
      width: 20px !important
      margin-left: 6px
