.Account
  display: inline-block
  position: relative
  height: 100%
  padding: var(--space-sm)
  background: transparent

.Account__MenuToggler
  display: flex
  flex-direction: row
  align-items: center
  text-decoration: none
  font-style: normal
  font-size: 12px
  line-height: 16px
  letter-spacing: 0.2px
  background: transparent
  border: none
  cursor: pointer
  position: relative
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  padding-right: var(--space-sm)

  .Account_noMenu &
    cursor: default
    padding: 0

.Account__MenuTogglerIcon
  width: 10px
  height: 6px
  position: absolute
  right: 0
  top: 50%
  transform: translateY(-50%)
  transition: all 0.3s
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  color: var(--color-on-surface-variant)

  .Account_noMenu &
    display: none

  .Account_isOpened &
    transform: translateY(-50%) rotateZ(180deg)

.Account__Avatar
  color: var(--color-on-surface-variant2)

.AccountInfo
  display: flex
  flex-direction: column
  margin-right: 1em
  color: var(--color-on-surface)

.AccountInfo__Name
  font-style: normal
  font-weight: normal

.AccountInfo__Role
  font-size: 10px
