$match-size: 150px

.MatchItem__Root
  display: flex
  flex-direction: column
  align-items: center

.Image__Wrapper
  width: $match-size
  height: $match-size
  position: relative

.Image
  width: $match-size
  height: $match-size
  object-fit: cover
  border: 1px solid var(--color-outline)
  border-radius: 4px

.MatchLink__Wrapper
  position: absolute
  right: 5px
  top: 5px

.SimilarityBadge
  position: absolute
  left: 5px
  top: 5px
