.FilterForm
  overflow-x: hidden
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: space-between
  height: calc(100vh - 80px)
  background-color: var(--color-surface)

.ButtonPanel
  display: flex
  justify-content: space-between

.Fold
  border: none
