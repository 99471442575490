.Track
  display: flex

.Row
  display: inline-flex
  margin: 30px

.TrackImage
  max-height: 150px
  object-fit: contain
