.Root
  width: 100%
  position: relative
  display: flex
  justify-content: center

.Count
  display: block
  text-align: right
  position: absolute
  padding: 2px
  top: 4px
  left: 4px

.PickerContainer
  display: flex
  justify-content: center
  align-items: flex-start
  overflow: hidden
  max-height: 360px

.Column
  min-width: 45%
  display: flex
  justify-content: center
  position: relative
