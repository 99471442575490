.ProgressCell
  display: flex
  width: 100%
  white-space: nowrap
  color: var(--color-on-surface)

.ProgressCell
  height: 20px
  width: 20px

  &_finished
    color: var(--color-positive)

  &_canceled
    color: var(--color-negative)
