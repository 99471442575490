@use 'sass:math'
@use '@vlabs/uikit/src/styles/typography.sass' as *

$sampleSize: 150px
$sampleBodyHeight: 300px

.EventTopMatch__Sample
  width: $sampleSize

  & .EventTopMatch__Sample_body
    width: math.div($sampleBodyHeight, 2)
    height: $sampleBodyHeight

.EventTopMatch__Info
  margin-left: 10px
  width: 100%

.EventTopMatch__SimilarityBadge
  font-size: 14px !important
  padding: 5px 5px !important
  text-align: center

.EventTopMatchCell__DeletedEvent
  display: inline-flex
  justify-content: center
  align-items: center
