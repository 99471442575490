@use '@vlabs/uikit/src/styles/typography.sass' as *

.TabPanel
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
  padding: 10px
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(150px, 150px))
  gap: 10px

.AdditionalPropertiesTab
  padding: 10px

.AttributesWrapper
  display: flex
  flex-direction: column
  align-items: flex-end

  &_margin
    margin-bottom: 5px
