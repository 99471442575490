
.PreviewImage
  height: 145px
  width: 145px

  &_body
    width: 128px
    height: 256px

.ButtonToggle
  display: flex
  width: 100%

.ButtonToggle__Item
  width: inherit

.EventPreview__Table_custom
  border: 1px solid var(--color-outline-variant)
  border-radius: 8px
  min-height: 145px

  .TableRow
    align-items: flex-start
