.Divider
  height: var(--space)
  width: var(--space)
  min-height: var(--space)
  min-width: var(--space)

  &_small
    height: var(--space-sm)
    width: var(--space-sm)
    min-height: var(--space-sm)
    min-width: var(--space-sm)
