@use '@vlabs/uikit/src/index.sass' as *

.Filter_flex
    display: flex
    align-items: flex-end

.FilterInputs_width
    width: 50%

.FilterDivider
    margin: var(--space-sm) var(--space-xs)
