$border-width: 10px

.ImagesPointsMatcher__Images
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: flex-start

  &_row
    flex-wrap: nowrap
    justify-content: space-between
    align-items: flex-start

.ImagesPointsMatcher__CanvasWrapper
  position: relative
  margin: $border-width
  outline: $border-width solid var(--color-outline-variant)
  transition: outline-color 0.1s

  &::before
    content: ''
    position: absolute
    width: 100%
    height: 100%
    opacity: 0.2
    background: repeating-linear-gradient(45deg, var(--color-outline), var(--color-outline) 10px, var(--color-outline-variant) 10px, var(--color-outline-variant) 20px)

  &_active
    outline-color: var(--color-positive)

.ImagesPointsMatcher__Tip
  margin-left: $border-width

.CanvasControls
  position: absolute
  top: 3px
  left: 3px

.CanvasControls__Button
  box-shadow: var(--elevation-shadow-1)
  & + &
    margin-left: 5px

.ImagesPointsState_loading
  text-align: center
  width: 100%
