@use '../../../styles/typography.sass' as *

.FileDropzone
  position: relative
  overflow: hidden
  min-height: 256px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  border: 2px dashed var(--color-outline)
  border-radius: 8px
  transition: border-color 0.2s, color 0.2s
  font-size: 16px
  padding: 4px
  padding-top: var(--space)
  color: var(--color-on-surface-variant)
  --overlay-bg: var(--color-primary)

  &:not([disabled])
    cursor: pointer

    &:not(.FileDropzone_error)
      &:hover,
      &:focus-visible,
      &.FileDropzone_highlighted
        border-color: var(--color-primary)
        color: var(--color-primary)
        outline: none

  &[disabled]
    border-color: var(--color-disabled-background)
    fill: var(--color-disabled-background)
    color: var(--color-disabled-foreground)

.FileDropzone_error
  fill: var(--color-negative)
  color: var(--color-negative)
  border-color: var(--color-negative)

.FileDropzone__Caption
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: var(--space-sm)

  svg
    width: 35px
    height: 35px

  &_filled,
  &_filled svg
    color: var(--color-primary)

.FileDropzone__CaptionTitle
  margin: var(--space-sm) 0 6px

.FileDropzone__Preview
  max-width: 100%
  max-height: 100%
  object-fit: contain

// повышаем специфичность
button.FileDropzone__ResetButton
  top: 4px
  right: 4px
  padding: 2px
  position: absolute
  z-index: 1

.FileDropzone__Input
  display: none

.FileDropzone__ErrorMessage
  position: absolute
  bottom: var(--space-sm)
  background: var(--color-negative-container)
  color: var(--color-on-negative-container)
  padding: 4px var(--space-sm)
  border-radius: var(--space-sm)
  display: flex
  justify-content: center
  align-items: center

  svg
    min-width: 16px
    min-height: 16px
    margin-right: 4px
