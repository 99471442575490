@use '@vlabs/uikit/src/styles/typography.sass' as *

.ImageCell__Wrapper
  display: flex
  justify-content: space-between
  align-items: center
  position: relative

  & > *
    margin-left: 5px

    &:first-child
      margin-left: 0

.ImageCell
  height: 100px
  margin-top: -16px
  margin-bottom: -16px

  &__NoPhoto
    width: 130px
    max-width: 100px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
    border: 1px solid var(--color-outline)
    color: var(--color-on-surface)
    background: var(--surface-container-high)
    box-sizing: border-box

    @include typ-small-1
