.LatestEventListPage__ArchiveButton
  margin-top: 10px
  text-align: center

.LatestEventListPage__Row__alert
  background: var(--color-negative-surface)
  font-weight: 500

.EventsTable__Page
  display: flex
  flex-direction: row
  padding: 10px 0 10px 10px

.TableWrapper
  width: 100%
  height: 100%
  transition: width 0.2s ease-in

.Table_custom

  .Table__ScrollContainer
    max-height: calc(100vh - 210px)

  .TableCellInner
    width: 100%
    height: 100%
    display: flex

  .TableBody
    overflow-y: auto

  .TableBottomPanel
    margin-top: 0
    padding: 5px
    display: flex
    justify-content: space-between
    align-items: center

  .TableCell
    padding: 0

  .TableCell:nth-child(1 of .TableCell)
    flex: 0 0 165px !important
    max-width: 165px !important

  .TableCell:has(.TopMatch_wrapper)
    align-self: stretch

  .TableHead:nth-child(1 of .TableHead) .TableRow:nth-child(1 of .TableRow) .TableCell
    padding: 10px 5px !important

  .TableRow
    align-items: flex-start

  .TableRow_Empty
    padding: 10px

    .TableCell
      width: max-content !important
