.TaskList__Buttons
  padding: var(--space-sm)
  border-bottom: 1px solid var(--color-outline-variant)

.TaskListPage__Table_fullScreen

  .Table__ScrollContainer
    max-height: calc(100vh - 280px)

  .TableBody
    overflow-y: auto
