.PaginationButton
  box-sizing: border-box
  height: 36px
  min-width: 36px
  padding: 3px
  padding-left: 0
  padding-right: 0

  &_active
    cursor: default
