@use '@vlabs/uikit/src/styles/typography' as *

.Root
  padding: 10px
  max-height: 75vh
  min-width: 55vw
  max-width: 90vw
  overflow: auto

.ColumnsContainer
  display: grid
  grid-template-areas: 'photo form fields'
  grid-template-columns: 1fr 1fr
  gap: 10px

.PhotoUploadColumn
  grid-area: photo

.FormFieldColumn
  grid-area: form fields
