:root,
*[data-theme="light"] {
  color-scheme: light;

  --color-primary: var(--palette-primary40);
  --color-on-primary: var(--palette-primary100);
  --color-primary-container: var(--palette-primary90);
  --color-on-primary-container: var(--palette-primary10);

  --color-neutral: var(--palette-neutral30);
  --color-on-neutral: var(--palette-neutral100);
  --color-neutral-container: var(--palette-neutral90);
  --color-on-neutral-container: var(--palette-neutral10);

  --color-positive: var(--palette-positive60);
  --color-on-positive: var(--palette-positive100);
  --color-positive-container: var(--palette-positive90);
  --color-on-positive-container: var(--palette-positive10);
  --color-positive-container-dim: var(--palette-positive96);

  --color-attention: var(--palette-attention70);
  --color-on-attention: var(--palette-attention100);
  --color-attention-container: var(--palette-attention90);
  --color-on-attention-container: var(--palette-attention10);
  --color-attention-container-dim: var(--palette-attention96);

  --color-negative: var(--palette-negative50);
  --color-negative-container: var(--palette-negative90);
  --color-on-negative: var(--palette-negative100);
  --color-on-negative-container: var(--palette-negative10);
  --color-negative-container-dim: var(--palette-negative94);

  --color-background: var(--palette-neutral99);
  --color-on-background: var(--palette-neutral10);

  --color-inverse-surface: var(--palette-neutral20);
  --color-inverse-on-surface: var(--palette-neutral95);
  --color-inverse-primary: var(--palette-primary80);

  --color-outline: var(--palette-neutral-variant50);
  --color-outline-variant: var(--palette-neutral-variant80);
  --color-outline-variant2: var(--palette-neutral-variant92);

  --color-disabled-background: var(--palette-neutral90);
  --color-disabled-foreground: var(--palette-neutral70);

  --color-nonactive-standout: var(--palette-neutral90);

  --color-surface: var(--palette-neutral98);
  --color-on-surface: var(--palette-neutral10);
  --color-surface-tint: var(--palette-primary40);
  --color-surface-variant: var(--palette-neutral-variant90);
  --color-on-surface-variant: var(--palette-neutral-variant30);
  --color-on-surface-variant2: var(--palette-neutral-variant60);
  --color-surface-container-highest: var(--palette-neutral90);
  --color-surface-container-high: var(--palette-neutral92);
  --color-surface-container: var(--palette-neutral94);
  --color-surface-container-low: var(--palette-neutral96);
  --color-surface-container-lowest: var(--palette-neutral100);
  --color-surface-dim: var(--palette-neutral87);
  --color-surface-bright: var(--palette-neutral98);
}