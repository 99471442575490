.Content
  margin: 10px 0 10px 10px

.Header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 10px

.Body
  display: flex
  flex-wrap: wrap
  height: calc(100vh - 130px)
  overflow: scroll

.Loader
  display: flex
  justify-content: center
  width: 100%
  height: 25px

.EmptyPage
  display: flex
  align-items: center
  justify-content: center
  height: calc(100vh - 80px)
  width: 100%
  font-size: 30px
  color: var(--color-on-surface)
  font-weight: 500

.InfoRow
  display: flex
  align-items: center

.InfoRowValue
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.Icon
  display: flex
  align-items: center
