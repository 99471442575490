.Icon
  cursor: pointer
  vertical-align: middle
  // color: $color-black-60
  transition: all 0.3s

  &:hover,
  &:focus
    // color: $color-primary-100

.DisabledIcon
  // color: $color-black-20
  cursor: default

  &:hover,
  &:focus
    // color: $color-black-20 !important
