@use '@vlabs/uikit/src/styles/typography.sass' as *

$sampleSize: 200px

.Root
  padding: 10px

.Badge
  padding: 2px 5px !important
  margin-left: 5px

.TitleBlock
  display: flex
  justify-content: space-between
  align-items: center

.Content
  display: grid
  grid-template-columns: 200px 1fr
  gap: 15px

.TabHeader
  white-space: nowrap
  padding: 0px 5px 5px
  @include typ-small-1

.InlineRow
  display: inline-flex
  margin-right: 10px

.ListInfoRow
  display: flex
  justify-content: flex-start

.FaceSample__Size_large
  height: $sampleSize
  width: $sampleSize

.BodySample__Size_large
  height: $sampleSize * 2
  width: $sampleSize

.BlockWithNoPermission
  display: flex
  flex-direction: column
  // align-items: center
  justify-content: center
  background: var(--color-surface-container-high)
  border: 1px solid var(--color-outline-variant)
  padding: 10px
  border-radius: 5px
  ccolor: var(--color-on-surface)
  font-size: 14px
