@use '../../styles/typography.sass' as *

.Table
  display: flex
  flex-direction: column

.TableTopPanel
  padding: var(--space-sm)

.Table__ScrollContainer
  overflow-x: auto
  display: flex
  flex-direction: column
  flex: 1 1 auto
  align-items: stretch

.TableHead
  @include typ-subtitle-2
  display: flex
  flex-direction: column
  border-bottom: 1px solid var(--color-outline-variant)

.TableHead__SortIcon
  display: inline-block
  width: 1em
  height: 1em
  vertical-align: middle

.FilterContainer
  margin-bottom: 5px

.TableBody
  @include typ-body-2
  display: flex
  flex-direction: column
  flex: 1 1 auto

.TableRow
  transition: all 0.3s
  align-items: center
  position: relative

  &:not(:last-child)
    border-bottom: 1px solid var(--color-outline-variant)

  .TableHead &
    align-items: flex-end

.TableRow__Overlay
  background: var(--color-on-surface)

.TableRow_clickable
  cursor: pointer

  &:hover .TableRow__Overlay
    opacity: var(--opacity-state-hover)

  &:active .TableRow__Overlay
    opacity: var(--opacity-state-active)

.TableRow_highlighted
  color: var(--color-on-primary-container)
  background: var(--color-primary-container)
  font-weight: 500

.TableRow_selected
  color: var(--color-on-primary-container)
  background: var(--color-primary-container)

.TableCell
  display: flex
  position: relative
  padding: var(--space-sm)
  word-break: break-word
  align-items: flex-start

  .TableHead &:not(.TableCell_action)
    flex-direction: column

.TableCell_action
  height: 100%
  padding: 0
  justify-content: center
  align-items: center
  align-self: center
  max-width: 56px
  box-sizing: content-box !important

.TableCell_canSort
  display: flex
  align-items: center
  transition: color 0.3s

  &:hover
    .TableHead__SortIcon, .TableHead__BaseSortIcon
      color: var(--color-primary)

.TableHead__BaseSortIcon
  display: inline-block
  width: 1em
  height: 1em
  vertical-align: middle
  color: var(--color-outline-variant)

.TableCell_overflowScroll
  word-break: unset

  .TableCellInner
    overflow: auto

.TableCell_overflowEllipsis
  .TableCellInner
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
