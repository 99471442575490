@use '@vlabs/uikit/src/styles/typography.sass' as *

$iconSize: 15px

.Root
  @include typ-body-2
  height: 20px
  display: flex
  align-items: center
  flex: 0 1 auto

.EventLink
  @include typ-subtitle-2
  margin: 3px 5px

.Icon
  height: $iconSize
  width: $iconSize
