.Icon
  width: 20px
  height: 20px
  vertical-align: middle

.StatusCell
  display: inline-flex
  padding: .25em .5em
  gap: .5em
  align-items: center

.success
  color: var(--color-positive)

.warning
  color: var(--color-attention)

.error
  color: var(--color-negative)

.GroupCell
  display: block
