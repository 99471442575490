$sampleSize: 200px

.Img_subtitle
  display: flex
  justify-content: center

.InfoWrapper
  padding: 10px 32px 10px 32px

.ImageWrapper
  height: $sampleSize
  width: $sampleSize !important

.Empty_Image
  display: inline-flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  border-radius: 4px
  border: 1px solid var(--color-outline-variant)
  color: var(--color-on-surface)
  background: var(--color-surface-container-high)
  box-sizing: border-box
  height: $sampleSize
  width: $sampleSize

.TabHeader
  border-top-left-radius: 8px
  border-top-right-radius: 8px
