
.Root
  min-width: 600px
  overflow: visible

.EmployeeCounter
  display: flex

.EmployeeCounterWidth
  width: 100%

.Tooltip
  max-width: 250px
  word-break: break-word
  display: flex
  align-items: center
  margin-top: 20px

.Icon
  height: 18px
  color: var(--color-on-surface)

  &:hover
    transition: all .3s
    color: var(--color-primary)

.ButtonPanel
  display: flex
  justify-content: flex-end
