:root
  --navbarHeight: 80px

.App
  background: var(--color-surface-container-lowest)
  min-width: 100%
  min-height: 100vh
  display: flex
  flex-direction: column

.AppContent
  flex: 1
  position: relative
