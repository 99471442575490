$border-radius: 12px

.Switch
  --overlay-border-radius: #{$border-radius}

  & > *
    vertical-align: middle

  .react-switch-bg
    border: 1px solid var(--color-primary)
    border-radius: $border-radius !important

  &_on
    .react-switch-bg
      background: var(--color-primary) !important

    .react-switch-handle
      background: var(--color-on-primary) !important

  &_off
    .react-switch-bg
      background: var(--color-nonactive-standout) !important
      border-color: var(--color-outline)

    .react-switch-handle
      background: var(--color-outline) !important

  &_disabled
    &.Switch_on
      .react-switch-bg
        background: var(--color-disabled-background) !important
        border-color: var(--color-outline-variant)

      .react-switch-handle
        background: var(--color-disabled-foreground) !important

    &.Switch_off
      .react-switch-bg
        background: var(--color-disabled-background) !important
        border-color: var(--color-disabled-background)

      .react-switch-handle
        background: var(--color-disabled-foreground) !important

.Switch__Input
  margin-right: 10px
  opacity: 1 !important

.Switch__Wrapper
  position: relative
  display: inline-block
