@use 'sass:math'
@use '@vlabs/uikit/src/styles/typography.sass' as *
@import app/styles/mixins

$sampleSize: 150px
$sampleBodyHeight: 300px

.SampleWithoutPermission
  background: var(--color-surface-container-high)
  border: 1px solid var(--color-outline-variant)
  padding: 10px
  border-radius: 5px
  color: var(--color-on-surface)
  font-size: 0.8em
  height: $sampleSize
  width: $sampleSize
  display: grid
  place-items: center

.SampleWithoutPermission_body
  height: $sampleBodyHeight
  width: math.div($sampleBodyHeight, 2)

.SampleWithoutPermission .StaticIcon
  height: 15px
  width: 15px
  color: var(--color-negative)
