@use '../../styles/typography.sass' as *

$toggle-duration: 0.2s
$border-radius: 8px

.Fold
  margin: 0
  padding: 0
  border-radius: $border-radius
  --overlay-border-radius: #{$border-radius}
  border: 1px solid var(--color-outline-variant)
  --transition-secondary-delay: 0s
  overflow: hidden

.Fold__Header
  padding: 12px 20px 12px 15px
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  border-bottom: 0 var(--color-outline-variant) solid
  transition: border-bottom-color 0.3s ease-out
  cursor: pointer

  &_isDisabled
    background-color: var(--color-disabled-background)
    color: var(--color-disabled-foreground)
    cursor: default

    & > .InteractionOverlay
      display: none

.Fold__Title
  display: flex
  width: 60%

.Fold__Tooltip
  display: flex
  align-items: center
  margin: 3px 0 0 5px

.Fold__Tooltip svg
  height: 18px

.Fold__ToggleIcon
  transition: transform $toggle-duration var(--transition-toggle-function)
  color: var(--color-on-surface-variant)
  position: absolute
  right: 15px
  top: 50%
  transform: translateY(-50%)

.Fold__Body
  overflow: hidden
  transition: height $toggle-duration var(--transition-toggle-function) 0s, padding 0s linear var(--transition-secondary-delay), border-width 0s linear var(--transition-secondary-delay)
  // фикс от схлопывания
  padding-bottom: 1px

  &_collapsed
    height: 0

// Оператор прямого потомка ">" здесь везде обязателен,
// потому что бывают вложенные фолды

.Fold_compact
  & > .Fold__Header
    padding: 10px

    .Fold__Title
      @include typ-subtitle-1

    .Fold__ToggleIcon
      right: 10px

.Fold_withPadding > .Fold__Body > .Fold__BodyInner
  padding: var(--space-sm)

.Fold_withPadding.Fold_compact > .Fold__Body > .Fold__BodyInner
  padding: 10px

.Fold_isOpened
  --transition-toggle-function: ease-in
  --transition-secondary-delay: #{$toggle-duration}
  --overlay-border-radius: #{$border-radius} #{$border-radius} 0 0
  overflow: visible

  & > .Fold__Header
    border-width: 1px

    & > .Fold__ToggleIcon
      transform: rotate(180deg) translateY(50%)

  & > .Fold__Body
    overflow: inherit

.Fold_isClosed
  --transition-toggle-function: ease-out
  --transition-secondary-delay: 0s

  & > .Fold__Body
    border-width: 0
    padding: 0

.Fold_isDisabled

  & > .Fold__Header
    cursor: default

    & > .Fold__ToggleIcon
      color: var(--color-disabled-foreground)
      cursor: default
