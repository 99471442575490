@use '@vlabs/uikit/src/styles/typography.sass' as *

.wrapper
  padding: 13px 0px !important
  flex-direction: column
  align-items: flex-start

.divider
  height: 8px

.flex
  display: flex
  align-items: flex-end

.meta_flex
  display: flex
  align-items: center

.width
  width: 100%

.Subtitle
  @include typ-subtitle-2
