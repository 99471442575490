.Root
  max-width: 80vw
  max-height: 80vh
  padding: 30px

.ImageContainer
  position: relative
  width: 100%
  height: 100%

.Image
  width: 100%
  height: 100%

.Detection
  position: absolute
  border: 2px solid green
  border-radius: 8px
  display: none
