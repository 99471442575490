
.Tabs__Header
  border-radius: 8px 8px 0 0

.StreamListPage__Table_fullScreen
  .Table__ScrollContainer
    max-height: calc(100vh - 220px)
    overflow-x: initial
  .TableBody
    overflow-y: auto
