.RangeFilter
  display: flex
  align-items: center

.RangeFilter__Input
  width: 51px
  font-size: 13px

.RangeFilter__Divider
  margin: 0 6px
