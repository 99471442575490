$iconSize: 30px

.Page
  width: 500px

.Description
  font-weight: 500

.HeaderContainer
  display: flex
  align-items: center

.Icon
  height: $iconSize
  width: $iconSize
  margin-right: 15px
  color: var(--color-primary)
