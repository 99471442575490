.Navbar
  height: var(--navbarHeight)
  display: flex
  align-items: center
  justify-content: space-between
  background: var(--color-surface)
  padding: 0 var(--space)
  position: sticky
  top: 0
  z-index: 2
  border-bottom: 1px solid var(--color-outline-variant2)

  .Brand
    margin-right: var(--space)

.Navbar__Links
  display: flex
  height: 100%
  align-items: center
  flex-grow: 1
  justify-content: flex-end
