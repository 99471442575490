@use '@vlabs/uikit/src/styles/typography.sass' as *
@import app/styles/mixins

$sampleSize: 150px

.TopMatch_wrapper
  display: flex
  min-height: $sampleSize
  margin-right: 8px
  padding: 5px
  width: 100%

.TopMatchCard_color
  &_positive
    background-color: var(--color-positive-container-dim)

  &_attention
    background-color: var(--color-attention-container-dim)

  &_negative
    background-color: var(--color-negative-container-dim)


.BlockWithNoPermission
  display: flex
  align-items: center
  justify-content: center
  background: var(--color-surface-container-high)
  border: 1px solid var(--color-outline-variant)
  padding: 10px
  border-radius: 5px
  ccolor: var(--color-on-surface)
  font-size: 14px

.BlockWithNoPermission .StaticIcon
  margin-right: 8px
  font-size: 16px
  color: var(--color-negative)
