@use '../styles/animation.sass' as *

.FormPage
  display: flex
  align-items: center
  justify-content: center
  height: 100vh

.FormPage__Content
  width: 360px

.FormPage__Logo
  height: 40px
  margin: 0 auto var(--space)
  display: block

.FormPage__Panel
  padding: var(--space)

  &_withBottomButton
    padding-bottom: var(--space-sm)

  &_withError
    @include animation-bounce
