@use '@vlabs/uikit/src/styles/typography.sass' as *
@import app/styles/mixins

.Root
  padding: 10px
  height: calc(100vh - 80px)
  overflow-x: hidden
  box-sizing: border-box

.FileSelector_margin
  margin-top: 16px

.InfoCol_flex
  display: flex

.ButtonToggleGroup
  width: 45%

.ButtonToggle
  display: flex
  width: 100%

.ButtonToggle__Item
  width: inherit

.Filter__Col
  position: fixed
  top: 80px
  right: 0
  width: 25%
  overflow-x: scroll

  &::-webkit-scrollbar
    width: 0

.ButtonPosition
  display: flex
  justify-content: space-between
  margin: 16px

.Information
  width: calc(55% - 16px)
  margin-left: 16px
  position: relative

.InfoPanel
  display: flex
  border-radius: 8px
  padding: 12px
  background-color: var(--color-surface-container-high)

.Icon
  width: 35px
  margin-right: 5px
  color: var(--color-on-surface)

.PreviewImage
  margin: -16px
  margin-bottom: -16px
  height: 145px
  width: 145px

.Subtitle
  margin-bottom: 5px

.ErrorMessage
  @include typ-subtitle-2
  display: inline-block
  color: var(--color-negative)
  margin-top: 8px

.ListPicker_col
  display: flex
  align-items: flex-start
  flex-direction: column
  padding-top: 50px
  max-height: 440px

.Count
  display: block
  padding: 4px
