.Icon
  width: 20px
  height: 20px
  cursor: pointer
  vertical-align: middle
  color: var(--color-neutral)
  transition: all 0.3s

  &:hover,
  &:focus
    color: var(--color-primary)

  &_disabled
    width: 20px
    height: 20px
    pointer-events: none
    color: var(--color-disabled-foreground)
    cursor: default

.SettingWithIcon
  display: flex
  align-items: center
