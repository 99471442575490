@use '@vlabs/uikit/src/styles/typography' as *

.Root
  min-width: 30vw
  max-width: 90vw
  overflow: auto

.FileDropzone__Container
  min-height: 30vh
  max-height: 75vh

.TitltContainer
  display: flex

.Error
  @include typ-subtitle-2

  display: inline-block
  color: var(--color-negative)
  margin-top: 16px

.FileSelectorContainer
  min-height: 300px
