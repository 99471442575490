@use '@vlabs/uikit/src/styles/typography' as *

.Switch
  display: flex
  justify-content: space-between
  align-items: center

.Switch__Label
  width: 85%
  @include typ-body-2

.ResultTitle
  padding: 10px 0 0 0

.CircleIcon
  height: 1em
  width: 1em
  margin-right: 5px
  color: var(--color-on-surface)

.TabButton
  display: flex
  justify-content: center
