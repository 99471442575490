.Root
  max-height: 500px
  width: 410px
  margin-bottom: -30px
  overflow: scroll

  &::-webkit-scrollbar
    width: 0

.Matches
  display: flex
  flex-wrap: wrap

.MatchValue
  font-weight: bold

.Margin
  margin: 5px
