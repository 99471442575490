$imageSize: 200px

.RowInfoWidth
  width: 450px

.ButtonHolder
  display: flex
  justify-content: flex-end

.InfoRoot
  padding: var(--space-sm)
  display: flex
  align-items: center

.TabPanel
  border-top: 1px solid var(--color-outline-variant)
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
  height: 100%
  padding: 10px

.TablePanel
  border-top: 1px solid var(--color-outline-variant)
  border-bottom-left-radius: 8px
  border-bottom-right-radius: 8px
  height: 100%

.GoTo
  display: flex
  justify-content: center
  padding-bottom: 10px

  .Icon
    color: var(--color-primary)

.Image
  height: $imageSize
  width: $imageSize

.EventData
  padding: var(--space-sm)
  display: grid
  grid-template-areas: 'photo data'
  gap: 16px

.Photo
  grid-area: photo

.Data
  grid-area: data
  display: flex
  flex-direction: column
  gap: .5em

.DataEntry
  display: flex
  justify-content: space-between

.DataKey
  font-weight: bolder

.DataValue
  text-align: right

.PassportPhoto
  height: $imageSize
  width: $imageSize
  object-fit: contain

.DownloadButton
  height: min-content
  padding: 0

.SimilarityCell
  width: 170px