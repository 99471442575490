.ButtonToggleGroup
  display: inline-flex

.Button.ButtonToggleGroup__Item
  border-radius: 0

  &:first-of-type
    border-radius: 4px 0 0 4px

  &:last-of-type
    border-radius: 0 4px 4px 0

  &:not(&[disabled]) + &
    border-left-width: 0px

  &[disabled]:not(:last-child)
    border-right-width: 0px

.ButtonToggleGroup__Icon
  display: flex
  justify-content: center
  height: 1em

  svg
    height: 100%
    width: auto
