.Margin
  display: inline-block

  &--full
    margin: var(--space-sm)

  &--left
    margin-left: var(--space-sm)

  &--right
    margin-right: var(--space-sm)

  &--top
    margin-top: var(--space-sm)

  &--bottom
    margin-bottom: var(--space-sm)

  &--block
    display: block
