@use '../../../styles/typography.sass' as *

// TODO: сделать стили disabled и readonly инпутов более контрастными.
//  Плюс явно обозначить стили для плейсхолдера и текста внутри.
//  Сейчас это всё нечитаемо

$border-radius: 8px

.InputField
  display: flex
  flex-direction: column
  align-items: flex-start
  position: relative
  border-radius: $border-radius
  --overlay-border-radius: #{$border-radius}

  &_disabled
    background: var(--color-disabled-background)

.InputField__Overlay
  background: var(--color-neutral)
  border-radius: $border-radius

  .InputField:not(.InputField_disabled):hover &
    opacity: var(--opacity-state-hover)

.InputField__Input
  @include typ-body-1
  width: 100%
  padding: 8px 10px 8px 10px
  transition: all 0.3s
  caret-color: var(--color-primary)
  color: var(--color-on-surface)
  background: transparent

  &[disabled]
    cursor: default
    color: var(--color-disabled-foreground)

.InputField_style-default
  .InputField__Input
    border: 1px solid var(--color-outline)
    border-radius: $border-radius

    &[disabled]
      border-color: var(--color-outline-variant)

    &:focus
      border-color: var(--color-primary)
      outline: none

.InputField_style-implicit
  &.InputField,
  .InputField__Overlay
    border-radius: 0

  .InputField__Input
    border: 0
    border-bottom: 1px solid var(--color-neutral)

    &:focus
      border-color: var(--color-primary)
      outline: none

  .Button.InputField__Clear
    right: 0

.InputField_error
  .InputField__Input
    color: var(--color-negative)
    border-color: var(--color-negative)
    border-color: var(--color-negative)

    &:focus
      border-color: var(--color-negative)

  &.InputField_style-implicit
    border-bottom-color: var(--color-negative)

.InputField_withIcon .InputField__Input
  padding-left: calc(1.5em + var(--space-sm))

.InputField__Input_withButton
  padding-right: calc(1.5em + var(--space-sm))

.InputField__Icon
  position: absolute
  left: 8px
  top: 50%
  transform: translateY(-50%)
  color: var(--color-outline)
  display: flex
  align-items: center
  justify-content: space-between

  .InputField_disabled &
    color: var(--color-disabled-foreground)

.Button.InputField__Clear
  color: var(--color-outline)
  position: absolute
  right: 8px
  top: 50%
  transform: translateY(-50%)
