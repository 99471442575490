.Page
  padding: var(--space-sm)

$imageSize: 250px

.FacePhoto
  width: $imageSize
  height: $imageSize
  object-fit: contain

.PassportPhoto
  max-height: $imageSize

.Controls
  display: inline-flex
  gap: var(--space-sm)
