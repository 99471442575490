.CopyButton
  display: inline-flex
  align-items: center
  border: none
  outline: none
  background: transparent
  cursor: pointer
  transition: all 0.3s
  padding: 0
  color: var(--color-on-surface)

  &:hover
    color: var(--color-primary)

.CopyButton__Icon
  transition: all 0.3s
