.NavbarLink
  height: 100%
  display: flex
  align-items: center
  padding: 0 24px
  transition: all 0.3s
  text-decoration: none
  margin: 0 0.5px
  background: transparent
  color: var(--color-on-surface)
  padding-top: 3px
  border-bottom: 3px solid transparent
  position: relative

  &_active
    border-bottom-color: var(--color-primary)

.NavbarLink__Icon
  margin-right: 5px
  display: flex
  align-items: center
  width: 15px
  height: 15px
