@use '@vlabs/uikit/src/styles/typography.sass' as *

.Content
  display: flex
  align-items: center
  @include typ-subtitle-1

.CallbackPolicyItem
  display: flex
  flex-direction: column
  border-bottom: 1px solid var(--color-outline-variant)
  padding: 10px 0
