.CoordinatesInput
  display: flex

.CoordinatesInput__Input
  flex-grow: 1

.CoordinatesInput__ModeButton
  margin-left: 5px
  display: flex
  align-items: center
  height: 42px
  width: 50px

.CoordinatesInput__Tooltip
  margin-left: 5px
  height: 42px
  display: flex
  align-items: center

  .ModeContainer + &
    margin-left: 0

.CoordinatesInput__RoiForm
  max-height: 87vh
  overflow-y: scroll
