$control-size: 18px
.Wrapper
  padding: .5em

.Item
  display: flex
  justify-content: space-between
  align-items: center
  padding: .3em
  box-sizing: border-box
  border: 1px solid transparent
  transition: .3s background-color
  border-bottom: 1px solid var(--color-outline-variant)

.Controls
  display: flex
  gap: .5em

.Up, .Down, .Empty
  width: $control-size
  height: $control-size
  padding: 0
  transition: .3s color
  svg
    width: $control-size
    height: $control-size
