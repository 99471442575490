@use '@vlabs/uikit/src/styles/typography.sass' as *

.Container
  margin: 0
  padding: 0
  border-radius: 8px
  border: 1px solid var(--color-outline-variant)

.Header
  padding: 12px 15px
  border-bottom: 1px transparent solid
  border-color: var(--color-outline-variant)

.Item
  @include typ-subtitle-1
  padding: 13px 16px
  cursor: pointer
  transition: all .3s
  position: relative
  --overlay-bg: var(--color-primary)

  & + &
    border-top: 1px solid var(--color-outline-variant)

  &_disabled
    color: var(--color-disabled-foreground)
    cursor: default
