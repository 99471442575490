$sidebarWidth: 272px
$iconPanelWidth: 45px

.PageWithSidebar
  display: grid
  grid-template-areas: 'content rigth-icon-panel rigth-sidebar' 'content rigth-icon-panel rigth-sidebar'
  grid-template-columns: 1fr $iconPanelWidth 0
  transition: grid-template-columns 0.7s
  overflow: hidden
  height: calc(100vh - 80px)

.RigthSidebarIsOpen
  grid-template-columns: 1fr $iconPanelWidth $sidebarWidth

.RigthSidebar
  grid-area: rigth-sidebar
  border-left: 1px solid var(--color-outline-variant)
  width: $sidebarWidth
  box-sizing: border-box

.RigthIconPanel
  grid-area: rigth-icon-panel
  display: flex
  justify-content: center
  align-items: center
  height: calc(100vh - 80px)
  width: $iconPanelWidth

.Content
  grid-area: content
