.Loader
  width: 25px
  height: 25px
  border: 3px solid var(--color-on-surface)
  border-bottom-color: transparent
  border-radius: 50%
  display: inline-block
  box-sizing: border-box
  animation: rotation 1s linear infinite

  @keyframes rotation
    0%
      transform: rotate(0deg)

    100%
      transform: rotate(360deg)
