@use '@vlabs/uikit/src/styles/typography.sass' as *

.Fold
  margin-bottom: 10px !important

.Result__Rows
  padding: 10px

.Row
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 4px

.Column
  display: flex
  justify-content: center
  align-items: flex-start

.Subtitle
  padding-top: 10px
  padding-left: 20px
  @include typ-subtitle-2

.Tooltip_Cell
  width: 30px

.IconWrapper
  display: flex

.StateColor_success
  color: var(--color-positive)

.StateColor_error
  color: var(--color-negative)

.StateColor_neutral
  color: var(--color-neutral)

.StateColor_unknown
  color: var(--color-attention)

.Icon
  margin: 1px 5px 0 0
  height: 15px !important
