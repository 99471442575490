@use '../input/Input.sass'

.flatpickr-calendar
  border: 1px solid var(--color-outline-variant)
  box-shadow: var(--elevation-shadow-3)

.flatpickr-calendar.arrowTop::before
  border-bottom-color: var(--color-outline-variant)

.flatpickr-calendar.arrowTop::after
  border-bottom-color: var(--color-surface-container-high)

.flatpickr-innerContainer
  border: none
  background: transparent

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month
  fill: var(--color-on-surface)

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg
  fill: var(--color-primary)

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday
  background: var(--color-surface-container-high)

.flatpickr-current-month .numInputWrapper span:hover
  background: var(--color-surface-container)

.flatpickr-current-month .numInputWrapper span.arrowUp::after
  border-bottom-color: var(--color-on-surface-variant)

.flatpickr-current-month .numInputWrapper span.arrowDown::after
  border-top-color: var(--color-on-surface-variant)

.flatpickr-days
  background: var(--color-surface-container-high)
  border: none

.numInputWrapper,
.flatpickr-monthDropdown-months.flatpickr-monthDropdown-months,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
span.flatpickr-weekday
  color: var(--color-on-surface-variant)

.flatpickr-day
  color: var(--color-on-surface)

.flatpickr-day.inRange
  box-shadow: (-5px) 0 0 var(--color-primary-container), 5px 0 0 var(--color-primary-container)
  color: var(--color-on-surface)

.flatpickr-current-month .flatpickr-monthDropdown-months:hover,
.numInputWrapper:hover,
.flatpickr-day.today,
.flatpickr-day.today.inRange
  border-color: var(--color-outline)

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus
  background: var(--color-primary-container)
  border-color: var(--color-primary-container)

.flatpickr-day.flatpickr-day.today:not(.selected):hover
  background: var(--color-surface-variant)
  color: var(--color-on-surface)

.flatpickr-day.today.inRange
  border-color: var(--color-outline)

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay
  background: var(--color-primary)
  border-color: var(--color-primary)
  color: var(--color-on-primary)

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1))
  box-shadow: (-10px) 0 0 var(--color-primary)
