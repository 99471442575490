.State
  display: inline-block
  width: 10px
  height: 10px
  border-radius: 50%
  background-color: var(--color-primary-container)
  margin-right: 10px
  border: 1px solid var(--color-neutral)

  &_running
    background-color: var(--color-positive)
    border-color: var(--color-positive)

  &_notRunning
    background-color: var(--color-negative)
    border-color: var(--color-negative)
