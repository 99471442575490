.TagCreatePage
  padding: 10px
  max-height: 700px
  overflow-y: scroll

.TagCreateForm
  & + &
    border-top: 1px solid var(--color-outline-variant)

.TagCreateForm__Row

  &_flex
    display: flex
    justify-content: space-between
    align-items: center

  &_subform
    padding: 16px 0px

.TagPolicy_AgeInputs
  width: 70px

.TagPolicy_AgeFilter
  display: flex
  justify-content: space-between
  align-items: center
  padding: 16px 10px

.TagCreateForm__MatchSubform
  padding: 16px 0px

.TagPolicy_ListWrapper
  margin-left: -5px
