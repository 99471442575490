.Checkbox__Label
  display: inline-flex
  align-items: center
  user-select: none
  cursor: pointer
  vertical-align: middle
  position: relative

  &_withText .Checkbox__Box
    margin-right: 10px

.Checkbox__Box
  display: inline-block
  width: 20px
  height: 20px
  flex-shrink: 0
  flex-grow: 0
  border: 1px solid var(--color-outline)
  border-radius: 0.25em
  background-repeat: no-repeat
  background-position: center center
  background-size: 50% 50%
  transition: border 0.1s, background-color 0.1s, color 0.1s
  padding: 2px
  display: flex
  justify-content: space-between
  align-items: center
  position: relative

  svg
    width: 100%
    height: 100%
    display: none

.Checkbox__BoxOverlay
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: var(--color-primary)
  opacity: 0

.Checkbox__Input
  position: absolute
  z-index: -1
  opacity: 0
  width: 0.1px
  height: 0.1px

  &:checked + .Checkbox__Label .Checkbox__Box
    border-color: var(--color-primary)
    background-color: var(--color-primary)

    svg
      display: block
      color: var(--color-on-primary)

.Checkbox:not(.Checkbox_disabled):hover
  .Checkbox__BoxOverlay
    opacity: var(--opacity-state-hover)

//
// STATES
//

.Checkbox_disabled
  .Checkbox__Box
    border-color: var(--color-outline-variant)

  .Checkbox__Input:checked + .Checkbox__Label .Checkbox__Box
    opacity: 0.6

  .Checkbox__Label
    cursor: default
    color: var(--color-disabled-foreground)
