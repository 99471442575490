@use '@vlabs/uikit/src/styles/typography' as *

.Root
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 13px 16px
  border-top: 1px solid var(--color-outline-variant)

.Title
  display: flex
  width: 60%
  align-items: center
  transition: color 0.3s
  @include typ-subtitle-1

  &_disabled
    color: var(--color-disabled-foreground)

.Lists
  min-width: 250px

.ListsItem
  & + &
    margin-top: .5em
