.Subtitle
  margin-bottom: 5px

.ButtonToggle
  width: 100%
  margin-bottom: 16px

.ButtonToggle__Item
  width: inherit
  padding: 0.5em 0em

.Sample
  width: 150px
  height: 150px
