.Root
  overflow-x: hidden
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: space-between
  height: calc(100vh - var(--navbarHeight))
  background-color: var(--color-surface)

.ButtonToggle
  width: 100%

.ButtonToggle__Item
  width: inherit

.ButtonPanel
  display: flex
  justify-content: space-between
