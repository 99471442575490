.Wrapper
  :global
    .SettingsItemWrapper
      padding: 0
    .SettingsItemWrapper__Title
      padding-right: var(--space-sm)
      width: auto
      font-weight: 400
    .Grid
      padding-top: var(--space)
    .GridRow
      margin-top: var(--space-sm)
    .GridCol
      padding-top: 0
      padding-bottom: 0
