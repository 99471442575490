.TaskStepperModal__Page
  padding: 10px
  margin: 10px
  width: 700px
  overflow: auto

.TaskStepperModal__StepperContent
  display: flex
  flex-direction: column
  height: 65vh
  > :last-child
    margin-top: auto
