@use '../../styles/typography.sass' as *

.NotificationBubble
  display: inline-block
  height: 20px
  min-width: 20px
  margin: 0 0.5em
  text-align: center
  padding: 2px 0.5em
  border-radius: 1em
  background: var(--color-primary)
  color: var(--color-on-primary)
  transition: all 0.3s
  flex-shrink: 0

  &_attention
    background: var(--color-negative)
    color: var(--color-on-negative)
