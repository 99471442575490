.UnauthorizedPage
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

.UnauthorizedPage__Content
  font-size: 30px
  color: var(--color-on-surface-variant)
  font-weight: 500
