@use '@vlabs/uikit/src/styles/typography.sass' as *
@import app/styles/mixins

$sampleSize: 150px

.EventSampleCell__Root
  margin-right: 0px !important
  flex-direction: column
  display: flex
  min-height: $sampleSize
  margin-right: 8px
  padding: 5px
  width: 100%

.EventSampleCell_color
  &_positive
    background-color: var(--color-positive-container-dim)

  &_attention
    background-color: var(--color-attention-container-dim)

  &_negative
    background-color: var(--color-negative-container-dim)

.EventSample__Divider
  margin-top: 5px
