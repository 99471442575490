@use '../../styles/typography.sass' as *

.StepperHead
  display: flex
  justify-content: space-between
  margin: 20px 0
  align-items: center

.StepperHead__Item
  background-color: transparent
  padding: 10px
  display: flex
  align-items: center
  flex: 1 1 auto
  position: relative

  &_lastStep
    flex: 0 0 auto

.StepperHead__ItemCircle
  border-radius: 50%
  border: 2px solid var(--color-primary)
  background-color: var(--color-primary)
  width: 13px
  height: 13px
  text-align: center
  display: flex

  .StepperHead__Item_inactive &
    background-color: transparent
    border-color: var(--color-outline-variant)

.StepperHead__ItemName
  margin-left: 5px
  width: max-content
  color: var(--color-on-surface)

.StepperHead__CompletedIcon
  width: 13px
  height: 13px
  color: var(--color-on-primary)
  transition: all 0.2s

.StepperHead__Divider
  height: 1px
  background-color: var(--color-primary)
  width: 100%

  &_inactive
    background-color: var(--color-outline-variant)

.Stepper__PageTitle
  margin-bottom: var(--space-sm)

.Stepper__ButtonContainer
  margin-top: var(--space-sm)

.Stepper__Content
  display: none

  &_active
    display: block
