@mixin typ-h1
  font-weight: 300
  font-size: 52px
  line-height: 64px
  letter-spacing: 0.2px
  margin: 0

@mixin typ-h2
  font-weight: 500
  font-size: 44px
  line-height: 54px
  margin: 0

@mixin typ-h3
  font-weight: 600
  font-size: 32px
  line-height: 42px
  letter-spacing: 0.1px
  margin: 0

@mixin typ-h4
  font-weight: 500
  font-size: 26px
  line-height: 32px
  letter-spacing: 0.2px
  margin: 0

@mixin typ-h5
  font-weight: 600
  font-size: 20px
  line-height: 26px
  letter-spacing: 0.2px
  margin: 0

@mixin typ-h6
  font-weight: 500
  font-size: 18px
  line-height: 24px
  letter-spacing: 0.2px
  margin: 0

@mixin typ-subtitle-1
  font-weight: 500
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

@mixin typ-subtitle-2
  font-weight: 500
  font-size: 14px
  line-height: 20px
  letter-spacing: 0.1px

@mixin typ-body-1
  font-weight: normal
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

@mixin typ-body-2
  font-weight: normal
  font-size: 14px
  line-height: 20px
  letter-spacing: 0.1px

@mixin typ-small-1
  font-weight: 500
  font-size: 12px
  line-height: 16px
  letter-spacing: 0.2px

@mixin typ-small-2
  font-weight: normal
  font-size: 12px
  line-height: 16px
  letter-spacing: 0.2px

@mixin typ-small-3
  font-weight: normal
  font-size: 11px
  line-height: 14px
  letter-spacing: 0.2px

@mixin typ-initials
  font-weight: 600
  font-size: 13px
  line-height: 16px
  letter-spacing: 0.2px
