.Tabs
  width: 100%

.Tabs__Headers
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%
  z-index: 0

  &_compact
    justify-content: flex-start

.Tabs__Header
  position: relative
  text-align: center
  cursor: pointer
  padding: 8px var(--space-sm) 8px var(--space-sm)
  border-bottom: 2px solid var(--color-outline-variant2)
  transition: all 0.2s
  font-weight: 500
  cursor: pointer
  flex: 1 1 auto

  .Tabs__Headers_compact &
    flex: 0 0 auto

  &_fullWidth
    width: 100%

  &_disabled
    color: var(--color-disabled-foreground)
    background-color: var(--color-disabled)
    cursor: default

  &:not(&_disabled)
    &.Tabs__Header_active
      border-bottom-color: var(--color-primary)
      color: var(--color-primary)

.Tabs__Bodies
  width: 100%

.Tabs__Body
  display: none

  &_active
    display: block
