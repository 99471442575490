@use '@vlabs/uikit/src/styles/typography.sass' as *

.Root
  font-size: 9px
  display: inline-flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  width: 160px
  height: 160px
  border-radius: 4px
  border: 1px solid var(--color-outline-variant)
  color: var(--color-on-surface)
  background: var(--color-surface-container-high)
  box-sizing: border-box
  position: relative

.Tooltip
  left: 8px
  top: 8px
  position: absolute

  &_icon
    height: 18px
    color: var(--color-on-surface)

.GoTo
  position: absolute
  right: 4px
  top: 4px
