
.Content
  margin: 10px 0 10px 10px

.Header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 10px

.Header__Actions
  display: flex

.Body
  display: flex
  flex-wrap: wrap
  height: calc(100vh - 120px)
  overflow: scroll

.EmptyPage
  display: flex
  align-items: center
  justify-content: center
  height: calc(100vh - 265px)
  width: 100%
  font-size: 30px
  color: var(--color-on-surface)
  font-weight: 500
