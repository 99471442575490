@use '@vlabs/uikit/src/styles/typography.sass' as *

.MatchPolicy_Margin
  margin-right: 10px

.GenericPolicy_Plus
  margin-left: 158px !important

.LinkToListsPolicy_Button
  border-top: 1px solid var(--color-outline-variant)

.MatchPolicy_Dropdown
  width: 225px
  @include typ-subtitle-2

.MatchPolicy_Input
  width: 225px

.LinkToListsPolicy
  & + &
    border-top: 1px solid var(--color-outline-variant)

.Dropdown
  width: 225px
