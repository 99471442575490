.ConfirmPopup
  font-weight: normal
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.1px
  width: 400px
  padding: 24px
  text-align: left
  background: var(--color-surface-container-high)
  box-shadow: var(--elevation-shadow-3)
  border-radius: 8px
  color: var(--color-on-surface)
  word-break: break-all

.ConfirmPopup__Title
  display: flex
  margin-bottom: 10px

.ConfirmPopup__Title > *
  display: flex
  align-items: center

.ConfirmPopup__TitleText
  font-style: normal
  font-weight: 600
  font-size: 20px
  line-height: 26px
  display: flex
  align-items: center
  letter-spacing: 0.2px
  background-position: left
  background-repeat: no-repeat

.ConfirmPopup__Icon
  margin-right: 10px

  &_delete
    color: var(--color-negative)

  &_warning
    color: var(--color-attention)

.ConfirmPopup__Buttons
  display: flex
  justify-content: flex-end
  margin-top: 30px

.ConfirmPopup__Message
  word-break: break-word

// ----------------

@keyframes react-confirm-alert-fadeIn
  from
    opacity: 0

  to
    opacity: 1

.react-confirm-alert-blur
  filter: url(#gaussian-blur)
  filter: blur(2px)
  -webkit-filter: blur(2px)

.react-confirm-alert-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: var(--z-index-popover)
  background: var(--color-scrim)
  display: flex
  justify-content: center
  align-items: center
  opacity: 1
  animation: react-confirm-alert-fadeIn 0.2s forwards

.react-confirm-alert-body-element
  overflow: hidden
