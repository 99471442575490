.ProgressCell
  display: flex
  width: 100%
  white-space: nowrap

.ProgressCell

  &_finished
    color: var(--color-positive)

  &_cancelled
    color: var(--color-negative)
