.Preview
  height: auto
  display: block
  max-height: 125px
  width: 220px
  object-fit: cover

  &_noPhoto
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    align-items: center
    color: var(--color-on-surface)
    border: 1px solid var(--color-outline-variant)
    height: 120px
    border-radius: 4px

  &_offIcon
    height: 50px
