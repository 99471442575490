@use '@vlabs/uikit/src/styles/typography.sass' as *

.Results__TitleWrapper
  display: flex
  align-items: center

.Results__Title
  @include typ-subtitle-1
  margin-left: 12px
  width: 150px

.Results__Table_custom
  .TableRow
    display: flex
    align-items: flex-start

    .TableCell:last-child
      align-self: center

  .TableCellInner
    width: 100%
    height: 100%

.Search__Table_custom
  .Table__ScrollContainer
    overflow: hidden
  .TableCellInner
    display: flex
    width: 100%
    height: 100%
  .TableCell
    padding: 0
  .TableCell:first-child
    flex: 0 0 165px !important
  .TableHead:first-child
    .TableRow:first-child
      .TableCell
        padding: 10px !important
  .TableRow
    align-items: flex-start
  .TableRow_Empty
    padding: 10px
    .TableCell
      width: max-content !important
