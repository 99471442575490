:root
  --toastify-color-light: var(--color-surface-container-high)

  --toastify-color-progress-light: var(--color-primary)
  --toastify-color-progress-info: var(--color-neutral)
  --toastify-icon-color-info: var(--color-neutral)

  --toastify-color-success: var(--color-positive)
  --toastify-color-warning: var(--color-attention)
  --toastify-color-error: var(--color-negative)

.Toastify__toast
  border: 1px solid var(--color-outline-variant)
  border-radius: 8px
  box-shadow: var(--elevation-shadow-3)
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.1px
  color: var(--color-on-surface)

.Toastify__toast-body
  max-width: 100%

  div
    min-width: 0

.Toastify__progress-bar
  height: 6px
  bottom: -1px

.Toastify__close-button
  color: var(--color-on-surface-variant)
  position: absolute
  width: 16px
  height: 16px
  right: 4px
  top: 4px
