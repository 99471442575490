.Grid
  // TODO: выпилить вообще нафиг эти негативные марджины, переписать на гридах или на гепах
  // FIXME: magic numbers
  --grid-gutter: 1.219512195%
  margin-left: calc(-1 * var(--grid-gutter))
  margin-right: calc(-1 * var(--grid-gutter))
  margin-top: calc(-1 * var(--space))
  display: flex
  justify-content: space-between
  flex-wrap: wrap
