@use '@vlabs/uikit/src/styles/typography.sass' as *

.ErrorMessage
  @include typ-subtitle-2
  display: inline-block
  color: var(--color-negative)
  margin: 8px 0 0 8px

.Border
  border: 1px solid transparent
  border-radius: 8px

.Border__error
  transition: all 0.3s
  border: 1px solid var(--color-negative)

.TooltipWrapper
  display: flex
