@use '@vlabs/uikit/src/styles/typography.sass' as *

.Root
  display: flex
  align-items: center
  justify-content: space-between
  padding-bottom: 6px

.GroupLabel
  color: var(--color-on-surface-variant)
  @include typ-small-3

.GroupBadge
  display: inline-block
  text-align: center
  padding: 0.1em 0.5em
  border-radius: 2em
  background-color: var(--color-primary)
  color: var(--color-on-primary)
