.AccountMenuLinkWithPermissions
  height: 38px

.AccountMenuLink_disabled
  color: var(--color-disabled-foreground)
  cursor: default
  pointer-events: none
  height: 38px

  &:hover
    color: var(--color-disabled-foreground)
