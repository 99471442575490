@use 'sass:math'
@use '@vlabs/uikit/src/styles/typography.sass' as *
@import app/styles/mixins

$sampleSize: 200px
$sampleBodyHeight: 256px

.Image
  height: inherit
  width: inherit
  object-fit: cover

.Image_body
  object-fit: contain

.Empty_Image
  font-size: 9px
  height: inherit
  width: inherit

.Badge
  position: absolute
  font-size: 10px !important
  padding: 5px 5px !important
  margin: auto
  top: 5%
  right: 5%
  width: fit-content
  height: fit-content

.Wrapper
  width: $sampleSize
  height: $sampleSize
  position: relative
  border-radius: 8px
  & .Image
    border-radius: inherit

.Wrapper_body
  width: math.div($sampleBodyHeight, 2)
  height: $sampleBodyHeight

.Controls
  position: absolute
  bottom: 0px
  height: auto
  padding-top: 20px
  padding-bottom: 5px
  display: flex
  width: 100%
  justify-content: space-evenly
  align-items: center
  opacity: 0.9
  border-bottom-right-radius: 8px
  border-bottom-left-radius: 8px
  @include gradient-black-background-to-top()
