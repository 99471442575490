
.Preview
  display: block
  height: 140px
  width: 250px
  object-fit: cover
  line-height: 1
  margin: -14px auto
  border-radius: 4px

  &_noPhoto
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center
    align-items: center
    color: var(--color-on-surface)
    height: 120px
    border: 1px solid var(--color-outline-variant)
