@use '../../../styles/typography.sass' as *

.Textarea
  width: 100%
  padding: 8px 10px 8px 10px
  transition: all 0.3s, width 0, height 0
  caret-color: var(--color-primary)
  background: transparent
  color: var(--color-on-surface)
  border: 1px solid var(--color-outline)

  &:focus
    outline: none

  &[disabled]
    cursor: default
    color: var(--color-disabled-foreground)
    border-color: var(--color-outline-variant)
    background: var(--color-disabled-background)

.Textarea_style
  &-default
    border-radius: 8px

    &:focus
      border: 1px solid var(--color-primary)

  &-implicit
    background: transparent
    border: 0
    border-bottom: 1px solid var(--color-neutral)
    border-radius: 0

    &:focus
      border-color: var(--color-primary)

.Textarea_error
  border-color: var(--color-negative)

  &:focus
    border-color: var(--color-negative)
