@keyframes bounce
  0%
    transform: translateX(0)
  25%
    transform: translateX(-5px)
  50%
    transform: translateX(5px)
  75%
    transform: translateX(-5px)
  100%
    transform: translateX(0px)

@mixin animation-bounce()
  animation-name: bounce
  animation-timing-function: ease-in-out
  animation-duration: 0.5s
