@use '@vlabs/uikit/src/styles/typography.sass' as *

.Root
  display: flex
  flex-direction: column

.Sample_TemperatureBadge
  position: inherit !important
  font-size: 10px !important
  padding: 5px 5px !important
  width: fit-content
  height: fit-content

.BodyContainer
  display: flex
  flex-wrap: wrap
  padding-bottom: 5px

  & > *
    padding-right: 10px
    padding-top: 5px

.Attributes
  display: flex
  flex-wrap: wrap
  padding-bottom: 5px

  & > *
    padding-right: 10px
    padding-top: 5px
