.MenuItemContainer
  display: flex
  align-items: center

.MenuItemTextTooltip
  display: flex
  align-items: center
  width: 100%

.MenuItemIcon
  height: 42px !important
  width: 42px !important
  margin-right: 10px
  color: var(--color-primary)

  &_disabled
    color: var(--color-disabled-foreground)

.Tooltip__Icon
  height: 18px
  color: var(--color-on-surface)

  &_notAvailable
    color: var(--color-negative)
