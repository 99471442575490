.StepperWrapper
  display: flex
  align-items: center
  flex-direction: column

.StepperButtons
  width: 700px
  border: 1px solid var(--color-outline-variant)
  border-top: none
  border-radius: 0 0 8px 8px
  padding: 16px
