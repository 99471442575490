:root {
  --opacity-state-hover: 8%;
  --opacity-state-active: 12%;

  --opacity-state-hover-dense: 20%;
  --opacity-state-active-dense: 26%;

  --color-scrim-base: var(--palette-neutral0);
  /* --palette-neutral0 */
  --color-scrim: rgba(0, 0, 0, 0.32);
  --color-scrim-dense: rgba(0, 0, 0, 0.8);
  --color-on-scrim: var(--palette-neutral80);

  --color-primary-fixed: var(--palette-primary60);
  --color-on-primary-fixed: var(--palette-primary10);
  --color-primary-fixed-dim: var(--palette-primary80);
  --color-on-primary-fixed-variant: var(--palette-primary30);

  --color-positive-fixed: var(--palette-positive60);
  --color-on-positive-fixed: var(--palette-positive10);
  --color-positive-fixed-dim: var(--palette-positive80);
  --color-on-positive-fixed-variant: var(--palette-positive30);

  --color-attention-fixed: var(--palette-attention60);
  --color-on-attention-fixed: var(--palette-attention10);
  --color-attention-fixed-dim: var(--palette-attention80);
  --color-on-attention-fixed-variant: var(--palette-attention30);

  --color-negative-fixed: var(--palette-negative60);
  --color-on-negative-fixed: var(--palette-negative10);
  --color-negative-fixed-dim: var(--palette-negative80);
  --color-on-negative-fixed-variant: var(--palette-negative30);

  --color-neutral-fixed: var(--palette-neutral60);
  --color-on-neutral-fixed: var(--palette-neutral10);
  --color-neutral-fixed-dim: var(--palette-neutral80);
  --color-on-neutral-fixed-variant: var(--palette-neutral30);

  --elevation-shadow-3: 0px 4px 16px 0px #1F3BA03F;

  --elevation-shadow-2: 0px 4px 10px 0px #1F3BA033;

  --elevation-shadow-1: 0px 3px 8px 0px #1F3BA014;

  /* z-index */
  --z-index-dropdown: 0;
  --z-index-sticky: 1;
  --z-index-fixed: 2;
  --z-index-modal: 3;
  --z-index-popover: 4;
  --z-index-tooltip: 5;
}
