.Modal
  position: absolute
  top: 50%
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  background: var(--color-surface-container)
  box-shadow: var(--elevation-shadow-3)
  border-radius: 8px
  -webkit-overflow-scrolling: touch
  outline: none
  width: auto
  height: auto

.Modal__Overlay
  position: fixed
  inset: 0px
  z-index: var(--z-index-modal)
  background-color: var(--color-scrim)
  top: 0
  right: 0
  left: 0
  bottom: 0

.Modal__DocumentBody
  overflow: hidden

.Modal__CloseButton
  position: absolute !important
  right: 0
  top: 0
  z-index: var(--z-index-modal)
