@use '../../styles/typography.sass' as *

.Breadcrumbs
  color: var(--color-on-surface)

.Breadcrumbs__Separator
  margin: 0 0.5em
  vertical-align: bottom

  &::after
    content: '\203A'
    display: inline-block
    font-size: 16px
