@use '@vlabs/uikit/src/styles/typography.sass' as *

.NotificationForm__TargetsContainer
  padding: 13px 16px

.NotificationForm__TargetsTitle
  display: flex
  align-items: center
  @include typ-subtitle-1

.NotificationForm__FromToContainer
  display: flex
  align-items: flex-end
  width: 225px

.NotificationForm__FromTo
  width: 50%
  font-size: 13px

.NotificationForm__Divider
  margin: 12px 6px

.NotificationForm__Dropdown
  width: 225px
  @include typ-subtitle-2
