.AccountMenu
  position: absolute
  right: 0
  bottom: 0
  margin: 0
  padding: 0
  min-width: 150px
  list-style: none
  transform: translateY(100%)
  border-radius: 8px
  flex-direction: column
  overflow: hidden
  display: none
  border: 1px solid var(--color-outline-variant2)
  background: var(--color-surface-container)
  box-shadow: var(--elevation-shadow-2)
  white-space: nowrap

  &_visible
    display: flex
    z-index: var(--z-index-popover)
