.Step
  border: 1px solid var(--color-outline-variant)
  border-bottom: 0
  border-radius: 0
  width: 700px

.Step_sparse
  &>*
    margin-top: 8px

.Step_hollow
  border: none

.StepTitle_separate
  border-radius: 8px
  border-bottom: 1px solid var(--color-outline-variant)

.NoTitle
  border-radius: 8px 8px 0 0

.FoldedStep
  border-bottom: 0

  :global
    .Fold
      border: 0
