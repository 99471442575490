.Pagination
  display: flex
  align-items: center
  gap: 8px

  .Select
    margin-left: 0.5em

.Pagination__Icon
  color: var(--color-on-surface-variant2)
