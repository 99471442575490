.Detection
  border: 2px solid var(--color-on-neutral-fixed)
  box-sizing: border-box
  border-radius: 8px
  position: absolute
  cursor: pointer
  background-color: transparent
  transition: background-color 0.2s
  --overlay-bg: var(--color-attention-fixed)

  &_body
    border-color: var(--color-detection-body)
    z-index: 0

  &_face
    border-color: var(--color-detection-face)
    z-index: 1

  &:focus
    outline: none

  &:hover
    transition: none

  &_selected
    border-color: var(--color-positive)
