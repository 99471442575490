.TableBottomPanel
  padding: var(--space-sm)
  display: flex
  justify-content: space-between
  align-items: center
  border-top: 1px solid var(--color-outline-variant)

.TableBottomPanel__Controls
  display: flex

.TableBottomPanel__Controls > *:not(:first-child)
  margin-left: var(--space-sm)
