:root {
  --palette-primary-source: #007EFF;

  /* primary */
  --palette-primary0: #000000;
  --palette-primary4: #000d27;
  --palette-primary5: #00102c;
  --palette-primary6: #001330;
  --palette-primary10: #00033D;
  --palette-primary12: #001e47;
  --palette-primary17: #00295a;
  --palette-primary20: #002f66;
  --palette-primary22: #00336f;
  --palette-primary24: #003777;
  --palette-primary25: #003a7b;
  --palette-primary30: #004590;
  --palette-primary35: #0050a6;
  --palette-primary40: #005BFF;
  --palette-primary50: #0074eb;
  --palette-primary60: #468fff;
  --palette-primary70: #7dabff;
  --palette-primary80: #8AB9FF;
  --palette-primary87: #cadaff;
  --palette-primary90: #D7E7FF;
  --palette-primary92: #e0e8ff;
  --palette-primary94: #e8eeff;
  --palette-primary95: #ecf0ff;
  --palette-primary96: #f1f3ff;
  --palette-primary98: #f9f9ff;
  --palette-primary99: #fdfbff;
  --palette-primary100: #ffffff;

  /* secondary */

  --palette-secondary0: #000000;
  --palette-secondary4: #000f1e;
  --palette-secondary5: #001223;
  --palette-secondary6: #001527;
  --palette-secondary10: #001d34;
  --palette-secondary12: #00213a;
  --palette-secondary17: #002c4a;
  --palette-secondary20: #003355;
  --palette-secondary22: #00375b;
  --palette-secondary24: #003c62;
  --palette-secondary25: #003e66;
  --palette-secondary30: #004a78;
  --palette-secondary35: #00568a;
  --palette-secondary40: #00629d;
  --palette-secondary50: #287cbc;
  --palette-secondary60: #4996d8;
  --palette-secondary70: #67b1f4;
  --palette-secondary80: #99cbff;
  --palette-secondary87: #bfddff;
  --palette-secondary90: #cfe5ff;
  --palette-secondary92: #d9eaff;
  --palette-secondary94: #e3efff;
  --palette-secondary95: #e8f1ff;
  --palette-secondary96: #edf4ff;
  --palette-secondary98: #f7f9ff;
  --palette-secondary99: #fcfcff;
  --palette-secondary100: #ffffff;

  /* positive */
  --palette-positive0: #000000;
  --palette-positive4: #001204;
  --palette-positive5: #001506;
  --palette-positive6: #001807;
  --palette-positive10: #00210b;
  --palette-positive12: #00260e;
  --palette-positive17: #003214;
  --palette-positive20: #003918;
  --palette-positive22: #003e1b;
  --palette-positive24: #00431d;
  --palette-positive25: #00461f;
  --palette-positive30: #005225;
  --palette-positive35: #00602d;
  --palette-positive40: #006d34;
  --palette-positive50: #008943;
  --palette-positive60: #06CB98;
  --palette-positive70: #24c466;
  --palette-positive80: #4ce17f;
  --palette-positive87: #63f591;
  --palette-positive90: #9BF2DB;
  --palette-positive92: #93ffac;
  --palette-positive94: #b5ffc1;
  --palette-positive95: #c4ffcc;
  --palette-positive96: #DFFBF4;
  --palette-positive98: #eaffe9;
  --palette-positive99: #f5fff2;
  --palette-positive100: #ffffff;

  /* attention */
  --palette-attention0: #000000;
  --palette-attention4: #170c00;
  --palette-attention5: #1b0e00;
  --palette-attention6: #1e1100;
  --palette-attention10: #291800;
  --palette-attention12: #2e1c00;
  --palette-attention17: #3c2500;
  --palette-attention20: #452b00;
  --palette-attention22: #4a2f00;
  --palette-attention24: #503300;
  --palette-attention25: #533500;
  --palette-attention30: #624000;
  --palette-attention35: #724a00;
  --palette-attention40: #825500;
  --palette-attention50: #a26c00;
  --palette-attention60: #c58300;
  --palette-attention70: #FFAD05;
  --palette-attention80: #ffb94e;
  --palette-attention87: #ffd398;
  --palette-attention90: #FFE4AD;
  --palette-attention92: #ffe4c3;
  --palette-attention94: #ffebd4;
  --palette-attention95: #ffeedc;
  --palette-attention96: #FFF8EB;
  --palette-attention98: #fff8f4;
  --palette-attention99: #fffbff;
  --palette-attention100: #ffffff;

  /* neutral */
  --palette-neutral0: #000000;
  --palette-neutral4: #0d0e11;
  --palette-neutral5: #101114;
  --palette-neutral6: #131316;
  --palette-neutral10: #000D24;
  --palette-neutral12: #1f1f23;
  --palette-neutral17: #292a2d;
  --palette-neutral20: #001438;
  --palette-neutral22: #343438;
  --palette-neutral24: #39393c;
  --palette-neutral25: #3b3b3f;
  --palette-neutral30: #697386;
  --palette-neutral35: #525256;
  --palette-neutral40: #5e5e62;
  --palette-neutral50: #77767a;
  --palette-neutral60: #919094;
  --palette-neutral70: #A8B2C2;
  --palette-neutral80: #c7c6ca;
  --palette-neutral87: #ECEFF4;
  --palette-neutral90: #ECEFF4;
  --palette-neutral92: #EFF1F6;
  --palette-neutral94: #FFFFFF;
  --palette-neutral95: #FFFFFF;
  --palette-neutral96: #F5F9FF;
  --palette-neutral98: #FFFFFF;
  --palette-neutral99: #F3F8FF;
  --palette-neutral100: #F8FBFF;

  /* neutral-variant */
  --palette-neutral-variant0: #000000;
  --palette-neutral-variant4: #0b0e15;
  --palette-neutral-variant5: #0e1118;
  --palette-neutral-variant6: #10131a;
  --palette-neutral-variant10: #191c22;
  --palette-neutral-variant12: #1d2027;
  --palette-neutral-variant17: #272a31;
  --palette-neutral-variant20: #2e3038;
  --palette-neutral-variant22: #32353c;
  --palette-neutral-variant24: #363941;
  --palette-neutral-variant25: #393b43;
  --palette-neutral-variant30: #8D95A5;
  --palette-neutral-variant35: #50525a;
  --palette-neutral-variant40: #5c5e66;
  --palette-neutral-variant50: #B8C0CD;
  --palette-neutral-variant60: #B8C0CD;
  --palette-neutral-variant70: #a9abb4;
  --palette-neutral-variant80: #DBE0E7;
  --palette-neutral-variant87: #d8d9e3;
  --palette-neutral-variant90: #F5F7F9;
  --palette-neutral-variant92: #ECEFF4;
  --palette-neutral-variant94: #ecedf7;
  --palette-neutral-variant95: #eff0fa;
  --palette-neutral-variant96: #f2f3fd;
  --palette-neutral-variant98: #f9f9ff;
  --palette-neutral-variant99: #fefbff;
  --palette-neutral-variant100: #ffffff;

  /* negative */
  --palette-negative0: #000000;
  --palette-negative4: #280001;
  --palette-negative5: #2d0001;
  --palette-negative6: #310001;
  --palette-negative10: #410002;
  --palette-negative12: #490002;
  --palette-negative17: #5c0004;
  --palette-negative20: #690005;
  --palette-negative22: #710005;
  --palette-negative24: #790006;
  --palette-negative25: #7e0007;
  --palette-negative30: #93000a;
  --palette-negative35: #a80710;
  --palette-negative40: #ba1a1a;
  --palette-negative50: #DC3B75;
  --palette-negative60: #ff5449;
  --palette-negative70: #ff897d;
  --palette-negative80: #ffb4ab;
  --palette-negative87: #ffcfc9;
  --palette-negative90: #F6CFDD;
  --palette-negative92: #ffe2de;
  --palette-negative94: #FBECF2;
  --palette-negative95: #ffedea;
  --palette-negative96: #fff0ee;
  --palette-negative98: #fff8f7;
  --palette-negative99: #fffbff;
  --palette-negative100: #ffffff;
}
