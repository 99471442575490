
.Root
  display: flex
  justify-content: flex-start
  width: 100%

.File
  width: 40%
  margin-right: 20px

.Results
  width: 60%

.Wrapper
  display: flex
  flex-direction: column
  width: 60%

.Header
  margin-bottom: 7px
  padding: 7px 0

.ButtonToggle
  display: flex
  width: 100%
  margin-bottom: 7px

.ButtonToggle__Item
  width: inherit

.Switch
  display: flex
  justify-content: space-between
  align-items: center
  margin-left: 10px
  margin-top: 20px
