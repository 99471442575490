@use 'sass:math'

$sampleSize: 200px

.Page
  padding: 10px
  overflow-x: hidden

.Panel
  padding: 10px

.MatchTable__Title
  padding: 10px 10px 5px 10px

.MatchTable__Image
  height: 125px
  width: 125px

  &_body
    width: math.div($sampleSize, 2)
    height: $sampleSize
