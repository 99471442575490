.ROISelector
  width: min-content
  width: -moz-min-content
  width: -webkit-min-content
  min-width: 500px

.ROISelector__Buttons
  display: flex

.ROISelector__Hint
  color: var(--color-on-surface-variant)
  font-size: 14px
  line-height: 22px
  display: block
  word-break: break-word

.ROISelector__RawImageSize
  font-size: 18px
  line-height: 16px
  letter-spacing: 0.2px
  display: block

.ROISelector__Coordinates
  font-size: 14px
  line-height: 14px
  letter-spacing: 0.2px
  display: block
  margin: 0
