
.FormWidth
  width: 400px

.FaceCountColumn__Icon
  width: 20px
  height: 20px
  cursor: pointer
  vertical-align: middle
  color: var(--color-on-surface)
  transition: all 0.3s

  &:hover,
  &:focus
    color: var(--color-primary)
