.Link
  & svg path
    transition: all 0.3s
    stroke: var(--color-neutral)

  & svg:hover path
    stroke: var(--color-primary)
    transition: all 0.3s

.Icon
  vertical-align: middle
