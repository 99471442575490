.Field
  display: flex
  align-items: center
  width: 350px

.MinMaxField__From
  min-width: 120px

.MinMaxField__Divider
  margin: 12px 6px

.MinMaxField__Till
  min-width: 120px

.Inputs
  width: 100%

.FoldTitle
  display: flex

.Tooltip_Icon_notAvailable
  height: 18px
  color: var(--color-negative)
