@use '@vlabs/uikit/src/styles/typography.sass' as *

$sampleSize: 200px

.Root
  padding: 10px

.TitleBlock
  display: flex
  justify-content: space-between

.Content
  display: grid
  grid-template-columns: 200px 1fr
  gap: 15px

.TabHeader
  white-space: nowrap
  padding: 0px 5px 5px
  @include typ-small-1

.Empty_Image
  height: $sampleSize
  max-width: $sampleSize
  width: 100%

.FaceSample__Size_large
  height: $sampleSize
  width: $sampleSize
  margin-bottom: 5px

.BodySample__Size_large
  height: $sampleSize * 2
  width: $sampleSize
