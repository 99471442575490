.Result__Rows
  margin: 10px auto

.InfoText
  display: flex
  align-items: center
  padding: var(--space-sm)

.InfoIcon
  height: 20px
  width: 20px
  min-height: 20px
  min-width: 20px
  margin-right: 6px
