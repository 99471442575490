@use '@vlabs/uikit/src/index.sass' as *

.SoundNotification__Similarity
    align-items: baseline
    display: flex
    padding-top: var(--space-xs)

.SoundNotification__Label
    white-space: nowrap
    margin-right: var(--space-xs)

.FilterForm__Body
    padding: var(--space-sm)

.SoundNotification__Input
    margin-right: var(--space-xs)
