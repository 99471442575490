@use '@vlabs/uikit/src/styles/typography.sass' as *

.Root
  position: relative
  height: min-content

.GoToButton
  position: absolute
  top: 4px
  right: 10px
  z-index: var(--z-index-sticky)

.ActionsGroup
  width: 100%
  position: absolute
  bottom: 5px
  z-index: var(--z-index-sticky)
  display: flex
  justify-content: center
  align-items: center
