
$iconSize: 20px
.ProgressCell
  display: flex
  width: 100%
  white-space: nowrap

.ProgressCell
  &_finished
    height: $iconSize
    width: $iconSize
    color: var(--color-positive)

    &_canceled
      height: $iconSize
      width: $iconSize
      color: var(--color-negative)
