@use '../../styles/typography.sass' as *

.SettingsItemWrapper
  display: flex
  align-items: center
  justify-content: space-between
  padding: 13px var(--space-sm)

  & + &
    border-top: 1px solid var(--color-outline-variant)

.SettingsItemWrapper__Title
  display: flex
  width: 60%
  align-items: center
  transition: color 0.2s

  &_disabled
    color: var(--color-disabled-foreground)

.SettingsItemWrapper__Tooltip
  display: flex
  align-items: center
  margin-left: 5px

.SettingsItemWrapper__Tooltip_icon
  height: 18px

.Tooltip
  max-width: 250px
  word-break: break-word
