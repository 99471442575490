.GridCol
  flex-shrink: 0
  flex-grow: 0
  padding: var(--space) var(--grid-gutter) 0
  display: block
  flex-direction: column
  position: relative
  width: auto
  transition-delay: 0.1s
  transition: all 0.3s

  &__Content
    position: relative

  &--cols-1
    width: 8.33333333%

  &--cols-2
    width: 16.66666667%

  &--cols-3
    width: 25%

  &--cols-4
    width: 33.33333333%

  &--cols-5
    width: 41.66666667%

  &--cols-6
    width: 50%

  &--cols-7
    width: 58.33333333%

  &--cols-8
    width: 66.66666667%

  &--cols-9
    width: 75%

  &--cols-10
    width: 83.33333333%

  &--cols-11
    width: 91.66666667%

  &--cols-12
    width: 100%

  &__Auto
    flex: 1
    flex-grow: 1

  &__VerticalCenter
    display: flex
    justify-content: center
