.Root
  height: 100%

.Container
  height: 100%
  position: relative
  overflow: hidden

.Image
  max-height: 360px
  width: 100%
  object-fit: contain
  border-radius: 4px
