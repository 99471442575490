@use '../../../styles/typography.sass' as *

.LoginForm__ErrorMessage
  display: inline-block
  color: var(--color-negative)
  margin-top: 8px
  margin-bottom: var(--space-sm)

.LoginForm__Input
  min-width: 294px

.ForgotPassword
  margin-top: var(--space-sm)
  display: flex
  justify-content: center
