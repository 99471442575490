@use '@vlabs/uikit/src/styles/typography.sass' as *

.SearchByEventsForm
  max-height: 80vh
  overflow-y: scroll
  width: 850px

.SearchByEventsForm__BloсksWrapper
  padding: 16px 10px

  & + &
    border-top: 1px solid var(--color-outline-variant)

  &_flex
    display: flex
    justify-content: space-between
    align-items: center

.SearchByEvents__AgeInputs
  width: 70px

.SearchByEvents__AgeFilter
  display: flex
  align-items: flex-end

  &_50
    width: 50%

.SearchByEvents__InputLabel
  padding-bottom: 5px

.SearchByEventsForm__ResultsFilter
  display: flex
  justify-content: space-between
  align-items: center

.AgeFilterDivider
  margin: 12px 6px

.CandidateFilters__Subtitle
  @include typ-subtitle-1

.GridTense
  width: 100%
  .GridCol
    padding-top: var(--space-sm)
