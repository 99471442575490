@use '../../styles/typography.sass' as *

.Badge
  background: var(--color-surface)
  color: var(--color-on-surface)
  border-radius: 4px
  padding: 8px 5px

  &_positive
    background: var(--color-positive)
    color: var(--color-on-positive)

  &_attention
    background: var(--color-attention)
    color: var(--color-on-attention)

  &_negative
    background: var(--color-negative)
    color: var(--color-on-negative)
