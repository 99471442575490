.FaceListPage__Table_fullScreen

  .Table__ScrollContainer
    max-height: calc(100vh - 205px)

  .TableCellInner:first-child
    width: 100%
    height: 100%

  .TableBody
    overflow-y: auto
    overflow-x: hidden

  .TableBottomPanel
    margin-top: 0
    padding: 5px
    display: flex
    justify-content: space-between
    align-items: center

  .TableCell:not(.TableCell_action)
    padding: 10px

  .TableRow_Empty
    padding: 10px

    .TableCell
      width: max-content !important

.FaceListPage__Sample
  width: 150px
  height: 150px
